import { Link, PageProps } from "gatsby"
import React, { memo } from "react"
import { PageMeta } from "~seo"

interface NotFoundPageProps extends PageProps {}

const NotFoundPage: React.FC<NotFoundPageProps> = () => {
  return (
    <section
      className="bg-cover bg-top sm:bg-top"
      style={{
        backgroundImage:
          'url("https://images.unsplash.com/photo-1545972154-9bb223aac798?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3050&q=80&exp=8&con=-15&sat=-75")',
      }}
    >
      <PageMeta title={"Not found page"} />
      <div className="container">
        <p className="text-sm font-semibold text-tw-black text-opacity-50 uppercase tracking-wide">
          404 error
        </p>
        <h1 className="mt-2 text-4xl font-extrabold text-tw-white tracking-tight sm:text-5xl">
          Uh oh! I think you’re lost.
        </h1>
        <p className="mt-2 text-lg font-medium text-tw-black text-opacity-50">
          It looks like the page you’re looking for doesn`&apos;`t exist.
        </p>
        <div className="mt-6">
          <Link
            to="/"
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-tw-black text-opacity-75 bg-tw-white bg-opacity-75 sm:bg-opacity-25 sm:hover:bg-opacity-50"
          >
            Go back accueil
          </Link>
        </div>
      </div>
    </section>
  )
}

export default memo(NotFoundPage)
